// List group item toggle
.list-group-flush {
    .list-group-item {
        padding-right: 0;
        padding-left: 0;
    }
}

.list-group-item-toggle {
    + div {
        margin-top: ($spacer/4);
    }

    strong {
        cursor: pointer;

        &::before {
            transition: 200ms all ease-in;
            transform: rotate(90deg);
            vertical-align: middle;
            display: inline-block;
            font-family: 'base';
            margin-top: -0.1em;
            content: '\e008';
        }

        &.collapsed {
            &::before {
                transform: rotate(0deg);
            }
        }
    }
}
