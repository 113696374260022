$btn-mobile-menu-color:         $secondary;
$btn-mobile-menu-size:          16px;

.btn-mobile-menu {
    padding: round($btn-mobile-menu-size*1.2) round($btn-mobile-menu-size*0.7);
    transition: 300ms all ease-in;
    align-self: center;
    position: relative;
    cursor: pointer;
    display: flex;
    height: auto;
    width: auto;
    right: 0;
    left: 0;
    top: 0;

    &:hover,
    .open-menu & {
        transition: 300ms all ease-in;

        .lines {
            background-color: darken($btn-mobile-menu-color,25%);
            transform: scale(0.9);

            &::after,
            &::before {
                background-color: darken($btn-mobile-menu-color,25%);
            }
        }
    }

    .lines {
        background-color: $btn-mobile-menu-color;
        transition: 300ms all ease-in;
        display: inline-block;
        width: round($btn-mobile-menu-size*1.5);
        position: relative;
        height: round($btn-mobile-menu-size/5);

        &::after,
        &::before {
            background-color: $btn-mobile-menu-color;
            position: absolute;
            height: inherit;
            width: inherit;
            content: '';
        }

        &::before {
            transition: 200ms all ease-in;
            top: round(-$btn-mobile-menu-size/2);
            left: 0;

            .open-menu & {
                transform: rotate(45deg) scaleX(1.1);
                transition: 200ms all ease-in;
                transform-origin: top left;
                margin-top: -1px;
                margin-left: 3px;
            }
        }

        &::after {
            transition: 200ms all ease-in;
            bottom: round(-$btn-mobile-menu-size/2);
            left: 0;

            .open-menu & {
                transform: rotate(-45deg) scaleX(1.1);
                transition: 200ms all ease-in;
                transform-origin: bottom left;
                margin-left: 3px;
            }
        }

        .open-menu & {
            background-color: rgba($btn-mobile-menu-color, 0);
            transition: 200ms all ease-in;
        }
    }
}
