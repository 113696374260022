// Cookies
.cookies-section {
    background-color: $gray-200;
    position: relative;
    z-index: 99;

    .cookie-graphic {
        align-items: center;
        display: flex;
        width: 4rem;
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        display: flex;

        @include media-breakpoint-between(sm,lg) {
            padding-left: 4rem;
        }

        @include media-breakpoint-down(sm) {
            .btn {
                font-size: 0.85rem;
            }
        }

        @media all and (max-width: 374px) {
            .btn {
                font-size: 0.8rem;
            }
        }
    }

    .btn-danger {
        text-transform: lowercase;
        text-decoration: underline;
        color: #3d3e3e;
        border: 0;
        background: none;

        &:hover {
            text-decoration: none;
        }
    }
}
