// Footer
.footer-section {
    background-color: $primary;
    margin-top: auto;
    color: $white;

    .col-lg-3 {
        align-self: center;

        strong.text-lg {
            display: inline-block;
            margin-bottom: 8px;
        }

        ul {
            margin-bottom: 0;
        }
    }

    .social-media {
        margin-top: 0.5rem;
        color: $primary;

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0; left: 1rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 2rem;
        }

        a {
            &:hover {
                opacity: 0.75;
            }
        }
    }

    .footer-justitie {
        position: relative;
        max-width: 380px;

        img {
            margin-top: $spacer*3.5;
            margin-right: $spacer;
            max-height: 4em;
            flex-shrink: 0;

            @include media-breakpoint-up(lg) {
                margin-top: $spacer*4;
            }
        }

        div {
            flex-grow: 1;
        }

        @include media-breakpoint-down(lg) {
            p {
                margin-top: 3rem;
            }
        }
    }

    .footer-menu {
        @extend .list-inline;
    }
}
