$color-facebook:        #3b5998;
$color-foursquare:      #f94877;
$color-google:          #dd4b39;
$color-linkedin:        #0077b5;
$color-pinterest:       #bd081c;
$color-tumblr:          #35465c;
$color-twitter:         #1da1f2;
$color-vimeo:           #1ab7ea;
$color-youtube:         #cd201f;
$color-whatsapp:        #25d366;
$color-instagram:       #833ab4;

.social-media {
    @extend .list-inline;

    &:not(.social-media-mono) {
        .svg-icon {
            &_facebook {
                &:hover {
                    path {
                        fill: $color-facebook !important;
                    }
                }
            }
            &_instagram {
                &:hover {
                    path {
                        fill: $color-instagram !important;
                    }
                }
            }
            &_twitter {
                &:hover {
                    path {
                        fill: $color-twitter !important;
                    }
                }
            }
            &_linkedin {
                &:hover {
                    path {
                        fill: $color-linkedin !important;
                    }
                }
            }
            &_youtube {
                &:hover {
                    path {
                        fill: $color-youtube !important;
                    }
                }
            }
            &_pinterest {
                &:hover {
                    path {
                        fill: $color-pinterest !important;
                    }
                }
            }
        }

        @supports (mask-size: cover) {
            li.social-media-instagram {
                .svg-icon:hover {
                    mask-image: url('../../frontend/images/icons/svg/instagram.svg');
                    mask-size: cover;
                    mask-repeat: no-repeat;
                    mask-position: center;

                    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
                    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),

                        // left top
                    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
                    radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),

                        // right top
                    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
                    radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),

                        // right bottom
                    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),

                        // create a base coat to smooth - corner gradients
                    linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

                    path,
                    circle {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
