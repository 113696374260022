@mixin arrow(
    $arrow-prefix: '.',
    $arrow-background-color: $white,
    $arrow-border-color: $default,
    $arrow-shadow-color: #000,
    $arrow-size: 8px,
    $arrow-position-style: relative,
    $arrow-position-top: 0,
    $arrow-position-left: 2rem,
) {
    position: $arrow-position-style;

    #{$arrow-prefix}arrow {
        top: calc(#{$arrow-position-top} - #{$arrow-size} + 1px);
        left: $arrow-position-left;
        width: ($arrow-size*2);
        height: $arrow-size;
        position: absolute;
        z-index: 2;

        &::before {
            border-left: $arrow-size solid $arrow-background-color;
            border-top: $arrow-size solid transparent;
            position: absolute;
            font-size: 0;
            content: '';
            right: 0;
            top: 0;
        }

        &::after {
            border-right: $arrow-size solid $arrow-background-color;
            border-top: $arrow-size solid transparent;
            position: absolute;
            font-size: 0;
            content: '';
            left: 0;
            top: 0;
        }
    }

    #{$arrow-prefix}arrow-border {
        top: calc(#{$arrow-position-top} - #{$arrow-size});
        left: $arrow-position-left;
        width: ($arrow-size*2);
        height: $arrow-size;
        position: absolute;

        &::before {
            border-left: $arrow-size solid darken($arrow-border-color, 5%);
            border-top: $arrow-size solid transparent;
            position: absolute;
            font-size: 0;
            content: '';
            right: 0;
            top: 0;
        }

        &::after {
            border-right: $arrow-size solid darken($arrow-border-color, 5%);
            border-top: $arrow-size solid transparent;
            position: absolute;
            font-size: 0;
            content: '';
            left: 0;
            top: 0;
        }
    }

    #{$arrow-prefix}arrow-shadow {
        left: calc(#{$arrow-position-left} + #{$arrow-size*0.75});
        top: calc(#{$arrow-position-top} - (#{$arrow-size}/2));
        box-shadow: 0 0 $arrow-size rgba($arrow-shadow-color,1);
        background: transparent;
        height: ($arrow-size/2);
        width: ($arrow-size/2);
        position: absolute;
        z-index: 0;
    }
}
