@charset "utf-8";

// Required
@import 'variables';
@import 'bootstrap';
@import 'global';
@import 'helpers';

// Mixins
@import 'mixins/arrow';
@import 'mixins/list-inline';
@import 'mixins/placeholder';

// Components
@import 'components/alert';
@import 'components/btn-mobile-menu';
@import 'components/contact-box';
@import 'components/cookies-card';
@import 'components/dev-grid';
@import 'components/error-page';
@import 'components/form-feedback';
@import 'components/google-maps';
@import 'components/input-group-inside';
@import 'components/list-group';
@import 'components/placeholder';
@import 'components/popup';
@import 'components/text-underlined';
@import 'components/slider-media';
@import 'components/splide';
@import 'components/social-media';
@import 'components/svg-icon';
@import 'components/toggle-element';
@import 'components/tooltip';

// Sections
@import 'sections/cookie';
@import 'sections/footer';
@import 'sections/header';
@import 'sections/highlight';
@import 'sections/slider';

.site-wrapper {
    flex-direction: column;
    min-height: 100vh;
    display: flex;
}

.bg-primary {
    .btn-secondary {
        color: $primary;
    }
}

.bg-white {
    text-decoration: none;

    &:hover {
        &:is(a) {
            background-color: $white !important;
        }
    }
}

// Services
.service-item {
    @include transition();
    background-color: $white;
    display: block;
    height: 100%;
    box-shadow: $box-shadow;
    text-decoration: none;

    &:hover {
        box-shadow: $box-shadow-lg;
        transform: scale(1.05);
    }
}

// References
.reference-item {
    img {
        max-height: 96px;
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-2_5 {
        max-width: 20%;
        flex: 0 0 20%;
    }
}

.contact-image {
    position: relative;

    img {
        position: absolute;
        object-fit: cover;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
    }
}
