// Input group with input-group-text visually inside the input

.input-group-inside {
    position: relative;

    input {
        border: 0;
        outline: 0;
        background: none;
        box-shadow: none;
        position: relative;
        z-index: 2;
    }

    .input-group-append,
    .input-group-prepend {
        position: relative;
        background: none;
        box-shadow: none;
        z-index: 2;
        outline: 0;
        border: 0;

        .input-group-text {
            background: none;
            outline: 0;
            border: 0;

            .icon {
                margin-bottom: -0.1em;
                margin-top: 0.2em;
            }
        }
    }

    .input-group-prepend {
        .input-group-text {
            padding-right: 0;
        }
    }

    .input-group-append {
        .input-group-text {
            padding-left: 0;
        }
    }

    .input-group-inside-border {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
        background-color: $input-bg;
        border-width: $input-border-width;
        border-style: solid;
        border-color: $input-border-color;
        box-shadow: $input-box-shadow;
        border-radius: $input-border-radius;
    }

    input:focus {
        border: 0;
        outline: 0;
        background: none;
        box-shadow: none;

        ~ .input-group-inside-border {
            color: $input-focus-color;
            background-color: $input-focus-bg;
            border-color: $input-focus-border-color;
            outline: 0;

            @if $enable-shadows {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $input-focus-box-shadow;
            }
        }
    }
}
