// Highlight
.highlight-section {
    background-color: #fff;
    position: relative;

    &::before {
        background-color: $primary;
        top: 0; left: 0; right: 0;
        position: absolute;
        content: '';
        height: 50%;
    }

    .container {
        position: relative;
    }
}
