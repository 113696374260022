.splide {
    &:not(.is-active) {
        .splide__arrows {
            display: none;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }
    }
}
