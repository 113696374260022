// Slider media
.slider-media {
    .item {
        position: relative;

        a {
            overflow: hidden;
            display: block;

            img {
                transition: 200ms transform ease-in;
                transform: scale(1);
            }

            .icon {
                transition: 200ms transform ease-in;
                transform: translate(-50%,-50%) scale(1);
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }

                .icon {
                    transform: translate(-50%,-50%) scale(1.1);
                }
            }
        }

        .icon {
            text-shadow: 0 0 16px rgba(0,0,0,0.25);
            transform: translate(-50%,-50%);
            top: 50%; left: 50%;
            position: absolute;
            z-index: 2;
        }
    }
}
