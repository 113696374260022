@mixin list-inline (
    $list-seperator: '',
) {
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: $list-inline-padding;
        }
    }

    @if $list-seperator == '|' {
        li {
            &:not(:first-child) {
                &::before {
                    vertical-align: middle;
                    display: inline-block;
                    margin-right: 0.4em;
                    margin-left: -0.5em;
                    margin-top: -0.1em;
                    font-size: 0.9em;
                    content: '|';
                }
            }
        }
    }
}
