@mixin tooltip(
    $tooltip-background-color: $white,
    $tooltip-border-color: $default,
    $tooltip-arrow-size: 8px,
    $tooltip-arrow-position-top: 0,
    $tooltip-arrow-position-left: 2rem,
    $tooltip-width: 240px
) {
    position: relative;

    .tooltip {
        box-shadow: 0 4px 12px rgba($dark, 0.25);
        border-radius: $border-radius;
        border: 1px solid $tooltip-border-color;
        background-color: $tooltip-background-color;
        top: 100%; left: calc(#{-$tooltip-arrow-position-left} + #{$tooltip-arrow-size*2});
        margin-top: $tooltip-arrow-size;
        width: $tooltip-width;
        position: absolute;
        visibility: hidden;
        padding: $spacer;
        cursor: auto;

        @include arrow(
            $arrow-prefix: '.tooltip-',
            $arrow-background-color: $tooltip-background-color,
            $arrow-border-color: $tooltip-border-color,
            $arrow-size: $tooltip-arrow-size,
            $arrow-position-style: absolute,
            $arrow-position-top: 0,
            $arrow-position-left: 2rem,
        );

        &.right {
            right: calc(50% - 8px - 2rem);
            left: unset;

            .tooltip-arrow,
            .tooltip-arrow-border {
                right: 2.5rem;
                left: unset;
            }
        }

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
}

.tooltip-trigger {
    @include tooltip(
        $tooltip-arrow-position-left: 2.75rem
    );
}
