// Alert
.alert {
    margin-bottom: 1.5rem;
    @extend .mb-lc-0;

    .icon-cross {
        position: absolute;
        top: 0; right: 0;
        padding: inherit;
        cursor: pointer;
    }

    ul {
        margin-top: 0.25rem;
        padding-left: 1rem;
    }
}
