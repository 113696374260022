.site-wrapper {
    overflow: hidden;
    width: 100%;
}

// Max cols
.col-max-sm,
.col-max-md,
.col-max-lg {
    padding-right: $spacer;
    padding-left: $spacer;
    width: 100%;
}

.col-max-sm {
    max-width: map_get($grid-breakpoints,sm);
}

.col-max-md {
    max-width: map_get($grid-breakpoints,md);
}

.col-max-lg {
    max-width: map_get($grid-breakpoints,lg);
}


// Inverted link
.link-inverted {
    text-decoration: $link-hover-decoration;

    &:hover {
        text-decoration: $link-decoration;
    }
}


// Icons
.icon {
    vertical-align: middle;
    margin-bottom: -0.2rem;
    display: inline-block;
    margin-top: -0.2rem;

    &:not(:only-child):first-child {
        margin-right: 0.3rem;
    }
}


// Improved inline list behaviour
.list-inline {
    li {
        @extend .list-inline-item;
    }
}


// Default fluid images
img {
    @extend .img-fluid;
}


// -- Form styling

// Custom control label
.custom-control-label {
    &::after,
    &::before {
        top: 0.3em;
    }
}

// Label fix
label[for] {
    cursor: pointer;
}

// Buttons
.btn-success {
    color: $white;
}


// Last child margin fix
.mb-lc-0 > :last-child {
    margin-bottom: 0 !important;
}


// Bg light > card
.bg-light {
    .card {
        background: none;
    }
}
