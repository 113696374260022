// Cookies
.cookies-card {
    background-color: $gray-200;
    position: relative;
    z-index: 3;

    .cookie-graphic {
        align-items: center;
        display: flex;
        width: 3rem;
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        display: flex;

        @include media-breakpoint-between(sm,lg) {
            padding-left: 3rem;
        }
    }
}
