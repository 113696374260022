// Typography
.text {
    &-sm {
        font-size: 0.85em;
    }

    &-lg {
        font-size: 1.25em;
    }

    &-xl {
        font-size: 1.5em;
    }

    &-2x {
        font-size: 2em;
    }

    &-3x {
        line-height: 1em;
        font-size: 3em;
    }

    &-4x {
        line-height: 1em;
        font-size: 4em;
    }
}
