.svg-icon {
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    line-height: 1em;
    height: 1em;
    width: 1em;

    svg {
        vertical-align: top;
        height: 100%;
        width: 100%;
    }

    // Sizes
    &:not(.svg-icon-lg):not(.svg-icon-xl):not(.svg-icon-2x):not(.svg-icon-3x):not(.svg-icon-4x) {
        font-size: 1em;
    }

    &.svg-icon-4x {
        font-size: 4em;
    }

    &.svg-icon-3x {
        font-size: 3em;
    }

    &.svg-icon-2x {
        font-size: 2em;
    }

    &.svg-icon-xl {
        font-size: 1.6em;
    }

    &.svg-icon-lg {
        font-size: 1.3em;
    }
}
