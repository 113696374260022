[data-popup],
[data-clickable],
[data-toggle-element] {
    cursor: pointer;

    > *:not([href]):not(.tooltip) {
        pointer-events: none;

        > *:not([href]):not(.tooltip) {
            pointer-events: none;

            > *:not([href]):not(.tooltip) {
                pointer-events: none;

                > *:not([href]):not(.tooltip) {
                    pointer-events: none;

                    > *:not([href]):not(.tooltip) {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    [href] {
        pointer-events: visible !important;
    }

    img,
    figure,
    svg {
        user-select: none;
    }
}
