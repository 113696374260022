[data-menu-measure="child"] {
    white-space: nowrap;
}

.header-section {
    @extend .py-4;
    background: $primary;
    position: relative;
    color: $white;
    z-index: 100;
    right: 0;
    left: 0;
    top: 0;

    .container {
        align-items: center;
        position: relative;
        display: flex;
    }

    &.fixed {
        position: fixed;
    }

    .logo-container {
        .logo {
            text-decoration: none;
            margin-bottom: -3rem;
            position: relative;
            display: block;
            z-index: 101;

            img {
                vertical-align: top;
                display: inline-block;
                margin-right: 1.5rem;
                margin-top: -0.5rem;
                margin-left: 1rem;
                max-height: 8rem;
                width: auto;

                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                }

                @media all and (max-width: 479px) {
                    margin-top: -1.75rem;
                    margin-right: 1rem;
                    max-height: 6rem;
                }
            }

            > span.h3 {
                display: inline-block;
                vertical-align: top;
                margin-top: 1.5rem;

                @media all and (max-width: 479px) {
                    margin-top: -0.25rem;
                    font-size: 1.5rem;
                }
            }
        }
    }

    .menu-container {
        @include media-breakpoint-down(md) {
            .header-menu {
                @include transition;
                box-shadow: 0 12px 24px rgba(255,255,255,0.1);
                top: 96px;
                left: 0;
                right: 0;
                transform: translateY(-$spacer);
                background-color: $secondary;
                pointer-events: none;
                position: absolute;
                padding: $spacer;
                z-index: 100;
                opacity: 0;

                @media all and (max-width: 479px) {
                    top: 65px;
                }

                @include arrow(
                    $arrow-prefix: '.',
                    $arrow-background-color: $secondary,
                    $arrow-border-color: $default,
                    $arrow-shadow-color: #000,
                    $arrow-size: 12px,
                    $arrow-position-style: absolute,
                    $arrow-position-top: 0,
                    $arrow-position-left: calc(100% - 44px),
                );

                .open-menu & {
                    transform: translateY(0);
                    pointer-events: visible;
                    z-index: 100;
                    opacity: 1;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    .btn-secondary {
                        background-color: $primary;
                        border-color: $primary;
                        color: $secondary;
                    }

                    li {
                        padding: ($spacer/2);
                        margin: 0;

                        a {
                            text-decoration: none;
                            display: flex;
                            align-items: center;

                            .svg-icon {
                                position: relative;
                                margin-left: auto;
                                margin-right: 8px;

                                &::before {
                                    top: -4px; left: -4px; right: -4px; bottom: -4px;
                                    background-color: $dark;
                                    border-radius: 50%;
                                    position: absolute;
                                    content: '';
                                }

                                svg {
                                    @include transition;
                                    position: relative;
                                }
                            }

                            .svg-icon:not(.active) {
                                svg {
                                    transform: rotate(180deg);
                                    transform-origin: center;
                                }
                            }
                        }

                        ul {
                            overflow: hidden;
                            padding: 8px 0 0;
                            margin: 8px 0 0;

                            li {
                                border-left: 3px solid $primary;
                                padding-left: 16px;
                            }
                        }
                    }
                }
            }

            .btn-mobile-menu {
                margin-right: -($spacer/2);
                margin-top: $spacer*0.65;
            }
        }

        @include media-breakpoint-up(lg) {
            .header-menu {

                ul.nav {
                    @include list-inline;
                    margin-top: 1.5rem;

                    > li {
                        padding: 0 !important;
                        margin: 0 !important;

                        &:not(:last-child) {
                            margin-right: 1.5rem;
                        }

                        > .submenu {
                            margin: 0;
                            padding: 0;
                            display: inline-block;
                        }

                        > a,
                        > .submenu > a {
                            padding: 0.375rem 1rem;
                            text-decoration: none;
                            display: inline-block;
                            position: relative;

                            &.btn {
                                margin-left: 1rem;
                            }

                            &:not(.btn) {
                                border: 1px solid transparent;

                                span {
                                    position: relative;

                                    &::before {
                                        border-bottom: 2px solid $white;
                                        transition: 200ms all ease-in;
                                        bottom: -3px;
                                        left: 0;
                                        position: absolute;
                                        content: '';
                                        width: 0;
                                    }
                                }
                            }

                            &:hover {
                                &::before {
                                    width: 100%;
                                }
                            }
                        }

                        &.active {
                            > a,
                            > .submenu > a {
                                font-weight: bold;

                                &:not(.btn) {
                                    span:not(.svg-icon)::before {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .submenu {
                    position: relative;

                    ul.layer-2 {
                        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.075);
                        border-radius: 0.5rem;
                        background: $white;
                        pointer-events: none;
                        position: absolute;
                        min-width: 10rem;
                        color: $secondary;
                        font-size: 15px;
                        margin: 0;
                        z-index: -1;
                        opacity: 0;
                        padding: 0;
                        top: calc(100% + 4px);

                        li {
                            padding: 0.25rem 1rem;
                            list-style: none;
                            display: block;
                            margin: 0;

                            &.arrow {
                                padding: 0;
                            }

                            &.arrow + li,
                            &:not(.arrow):first-child {
                                padding-top: 0.75rem;
                            }

                            &:last-child {
                                padding-bottom: 0.75rem;
                            }

                            a {
                                text-decoration: none;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            &.active {
                                a {
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    &:hover {

                        &::before {
                            top: 0; left: 0; right: 0;
                            height: calc(100% + 8px);
                            position: absolute;
                            content: '';
                        }

                        @include arrow(
                            $arrow-prefix: '.',
                            $arrow-background-color: $white,
                            $arrow-border-color: $white,
                            $arrow-shadow-color: #000,
                            $arrow-size: 8px,
                            $arrow-position-style: relative,
                            $arrow-position-top: calc(100% + 4px),
                            $arrow-position-left: calc(50% - 20px),
                        );

                        ul.layer-2 {
                            display: block !important;
                            pointer-events: visible;
                            z-index: 2;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
