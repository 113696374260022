// Google Maps
#google_maps_wrapper {
    background: $light;

    width: 100%;
    position: relative;

    #google_maps {
        height: 300px;
    }

    #google_maps_info {
        background-color: $white;

        a {
            text-decoration: none;
        }

        @include media-breakpoint-down(sm) {
            border: 1px solid #ddd; border-top: 0;
            position: relative;
            padding: 1rem;
            width: 100%;

            address {
                display: flex;
                flex-wrap: wrap;
                margin-right: -1rem;
                margin-left: -1rem;
                margin-bottom: 0;

                span.h5 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    position: relative;
                    width: 100%;
                    padding-right: 1rem;
                    padding-left: 1rem;
                }

                p {
                    flex: 0 0 50%;
                    max-width: 50%;
                    position: relative;
                    width: 100%;
                    padding-right: 1rem;
                    padding-left: 1rem;
                    margin-bottom: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding: 1rem 5rem 1rem 1rem;
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
    }
}

// Images
.google-maps-image {

}
