$white:                                 #fff !default;
$gray-100:                              #f8f9fa !default;
$gray-200:                              #e9ecef !default;
$gray-300:                              #dee2e6 !default;
$gray-400:                              #ced4da !default;
$gray-500:                              #adb5bd !default;
$gray-600:                              #6c757d !default;
$gray-700:                              #495057 !default;
$gray-800:                              #343a40 !default;
$gray-900:                              #212529 !default;
$black:                                 #000 !default;

$grays: () !default;
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
    ),
    $grays
);

// Colors
$primary:                               #efc820;
$secondary:                             #272727;
$tertiary:                              #aaaaaa;
$success:                               #5bbd72;
$info:                                  #3b83c0;
$warning:                               #ebb141;
$danger:                                #d95c5c;
$default:                               #cccccc;
$font:                                  #212529;
$light:                                 $gray-100;
$dark:                                  $gray-800;

$theme-colors: (
    "primary":                          $primary,
    "secondary":                        $secondary,
    "tertiary":                         $tertiary,
    "success":                          $success,
    "info":                             $info,
    "warning":                          $warning,
    "danger":                           $danger,
    "default":                          $default,
    "font":                             $font,
    "black":                            $black,
    "white":                            $white,
    "light":                            $light,
    "dark":                             $dark,
);
