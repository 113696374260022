.text-underlined {
    margin-top: -3px;
    margin-bottom: $line-height-base+em;
    content: '';
    height: 3px;
    background-color: $font;
    width: 80px;
    border: 0;
    display: inline-block;
}
