@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.popup {
    animation: fadeIn 300ms;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($dark,0.8);
    display: flex;
    align-items: center;
    justify-items: center;
    z-index: 999;

    &__close {
        position: absolute;
        color: $white;
        height: 2rem;
        width: 2rem;
        right: 20px;
        top: 20px;
    }

    &__content {
        animation: fadeIn 300ms, zoomIn 300ms;
        background: $white;
        height: 800px;
        width: 800px;
        margin: auto;
        max-height: 100vh;
        max-width: 100vh;

        max-height: calc(100vh - 100px);
        max-width: calc(100vh - 100px);
    }
}
