@import 'variables/colors';

// Grid
$grid-gutter-width:                     2rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);


// Body
$body-bg:                               $white;


// Typography

// Bodytext
$font-family-base:                      'Poppins', sans-serif;
$line-height-base:                      1.5;
$font-size-base:                        1rem;
$font-weight-base:                      300;
$body-color:                            $gray-900;

$font-weight-bolder:                    600;

$paragraph-margin-bottom:               $line-height-base+rem;

// Headings
$headings-font-family:                  'Poppins', sans-serif;
$headings-font-weight:                  700;
$headings-color:                        inherit;

$h1-font-size:                          2.5em;
$h2-font-size:                          2em;
$h3-font-size:                          1.75em;
$h4-font-size:                          1.5em;
$h5-font-size:                          1.25em;
$h6-font-size:                          1em;

// Responsive Headings
$h1-font-size:                          clamp(1.5em, calc(1.3em + 1vw), 3em);

$headings-margin-bottom:                0.5em !default;
$headings-font-family:                  null !default;
$headings-font-weight:                  500 !default;
$headings-line-height:                  1.2 !default;
$headings-color:                        null !default;

// Display
$display1-size:                         6rem !default;
$display2-size:                         5.5rem !default;
$display3-size:                         4.5rem !default;
$display4-size:                         3.5rem !default;

// Link
$link-color:                            inherit;
$link-decoration:                       underline;
$link-hover-color:                      inherit;
$link-hover-decoration:                 none;


// Form
//$input-focus-box-shadow:              none;
//$btn-focus-box-shadow:                none;


// Box-shadows
$box-shadow-sm:                         0 .125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow:                            0 .375rem 0.75rem rgba($black, 0.075);
$box-shadow-lg:                         0 .500rem 1.50rem rgba($black, 0.075);


// Breadcrumb
$breadcrumb-bg:                         #fff;
$breadcrumb-padding-y:                  0;
$breadcrumb-padding-x:                  0;
$breadcrumb-margin-bottom:              2rem;

@import 'variables/input';
@import 'variables/btn';
