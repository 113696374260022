// Slider
.slider-section {
    position: relative;
    background: $white;

    &::before {
        background-color: $primary;
        top: 0; left: 0; right: 0;
        height: calc(100% - 96px);
        position: absolute;
        content: '';

        @include media-breakpoint-up(lg) {
            height: 50%;
        }
    }

    .slide-image {
        @include media-breakpoint-up(lg) {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            img {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                object-fit: cover;
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }

    .slide-caption {
        background-color: rgba($secondary,1);
        padding: ($spacer*2);
        position: relative;
        color: $white;

        .h5 {
            font-weight: 400;
        }

        @include media-breakpoint-up(lg) {
            background-color: rgba($secondary,0.75);
            padding: ($spacer*5) ($spacer*3);
            width: 510px;
        }
    }
}
